<template>
  <div class="animated fadeIn">
    <b-card>
      <DetailsFormWrapper
        ref="form"
        :id="id"
        :model="model"
        :data="data"
        :custom-buttons="customButtons"
        @custom-click="
          name => {
            this[name]();
          }
        "
        header="Incoming order"
        @loaded="onFormLoad"
      >
        <slot name="content">
          <b-tabs content-class="mt-3" justified>
            <b-tab title="Overview" active>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['ID']"
                    :properties="entity('ID')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['PO Number']"
                    :properties="entity('PO Number')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Warehouse']"
                    :properties="entity('Warehouse')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    ref="refDistributor"
                    v-model="data['Distributor']"
                    :properties="entity('Distributor')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['PO Date']"
                    :properties="entity('PO Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['Est Finish Date']"
                    :properties="entity('Est Finish Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Currency']"
                    :properties="entity('Currency')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Status']"
                    :properties="entity('Status')"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Pick List ID']"
                    :properties="entity('Pick List ID')"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Invoice Number']"
                    :properties="entity('Invoice Number')"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="6" sm="6">
                  <FormInput
                    v-model="data['Notes']"
                    :properties="entity('Notes')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created By']"
                    :properties="entity('Created By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created']"
                    :properties="entity('Created')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified By']"
                    :properties="entity('Modified By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified']"
                    :properties="entity('Modified')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
              </b-row>
              <hr />

              <incoming-order-items-table
                ref="incoming-order-items"
                :order-id="id"
                :form-data="data"
                :read-only="isReadOnlyRecord"
                @changed="onItemsChange"
              ></incoming-order-items-table>

              <b-row>
                <b-col lg="6" md="6" sm="6"> </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Discount']"
                    :properties="entity('Discount')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Total']"
                    :properties="entity('Total')"
                  ></FormInput>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="controls.tabs.files.title"
              v-if="$form.mode(this) !== $constants.FORM_MODE.CREATE"
            >
              <files-container
                :module-id="$route.meta.module.id"
                :entity-id="id"
                @loaded="onFileContainerLoad"
              />
            </b-tab>
          </b-tabs>
        </slot>
      </DetailsFormWrapper>
    </b-card>
  </div>
</template>

<script>
import models from "@/models";
import modelHelpers from "@/models/helpers";
import IncomingOrderItemsTable from "@/views/Warehouse/IncomingOrders/IncomingOrderItemsTable";

export default {
  name: "IncomingOrderSubmission",
  components: { IncomingOrderItemsTable },
  props: {
    id: {
      type: [String, Number],
      default: ""
    }
  },
  data: function() {
    return {
      isReadOnlyRecord: false,
      model: models.warehouse.incomingOrders,
      data: {},
      controls: {
        tabs: {
          files: {
            title: "Files"
          }
        }
      },
      customButtons: [
        {
          text: "Convert to picklist",
          icon: "receipt",
          loading: false,
          visible: false,
          method: "convert2picklist",
          variant: "outline-dark"
        },
        {
          text: "Open picklist",
          icon: "receipt",
          loading: false,
          visible: false,
          method: "openPicklist",
          variant: "outline-dark"
        },
        {
          text: "Open invoice",
          icon: "receipt",
          loading: false,
          visible: false,
          method: "openInvoice",
          variant: "outline-dark"
        }
      ],
      entity: modelHelpers.entity
    };
  },

  created() {
    this.setDefaults();
  },
  async mounted() {
    this.updateDistributorsOptions();
  },
  methods: {
    async convert2picklist() {
      let confirm = await this.$form.showConfirmation(
        `Incoming order will be converted to picklist. Do you want to proceed?`
      );

      if (!confirm) return;

      this.customButtons[0].loading = true;

      let result = await this.$api.post(
        `warehouse/incoming-order/${this.id}/convert`
      );

      this.customButtons[0].loading = false;

      if (result.id) {
        this.$form.makeToastInfo("Success");

        this.$refs["form"].initialize();
      }
    },
    async openPicklist() {
      this.$router.push({
        name: "Pick list submission",
        params: { mode: "view", id: this.data["Pick List ID"] }
      });
      //this.data["Pick List ID"]
    },
    async openInvoice() {
      this.$router.push({
        name: "WH Invoice submission",
        params: { mode: "view", id: this.data["Invoice Number"] }
      });
      //this.data["Pick List ID"]
    },

    setDefaults() {
      this.data = modelHelpers.getEmptyEntitiesObject(this.model.entities);
    },
    onFormLoad(payload) {
      if (!payload) return;

      this.data = { ...this.data, ...payload };

      this.data["Warehouse"] = {
        id: payload["Warehouse ID"],
        label: payload["Warehouse"]
      };

      this.data["Distributor"] = {
        id: payload["Account ID"],
        label: payload["Account Name"]
      };

      this.data["Currency"] = {
        id: payload["Currency ID"],
        label: payload["Currency"]
      };

      this.customButtons[0].visible = !(
        this.$form.mode(this) === this.$constants.FORM_MODE.CREATE ||
        (this.data && this.data["Status"] !== "Incoming order")
      );
      this.customButtons[1].visible = this.data["Pick List ID"] > 0;
      this.customButtons[2].visible = this.data["Invoice Number"] > 0;

      this.isReadOnlyRecord = !(
        this.model.actions.Edit && this.model.actions.Edit.validator(this.data)
      );

      this.updateDistributorsOptions();
    },
    async onFieldChange(e) {
      if (e.id === "discount") this.updateComputedFields();

      if (this.$form.mode(this) === this.$constants.FORM_MODE.VIEW)
        this.$refs["form"]
          .save("view")
          .then(() => this.$refs["form"].initialize());

      if (e.id === "warehouse") this.updateDistributorsOptions();
    },
    async updateDistributorsOptions() {
      let response = await this.entity("Distributor").optionsService();

      this.$refs.refDistributor.options = response
        .filter(i => i.bill_country === "United States")
        .map(i => ({
          id: i.id,
          label: i.name
        }));

      if (this.data["Warehouse"]) {
        if (this.data["Warehouse"].label.includes("4241")) {
          this.$refs.refDistributor.options = response
            .filter(i =>
              ["United States", "Japan", "Canada", "Taiwan"].includes(
                i.bill_country
              )
            )
            .map(i => ({ id: i.id, label: i.name }));
        } else {
          this.$refs.refDistributor.options = response.map(i => ({
            id: i.id,
            label: i.name
          }));
        }
      }
    },
    onItemsChange() {
      this.updateComputedFields();

      if (this.$form.mode(this) === this.$constants.FORM_MODE.VIEW) {
        this.$refs["form"].save("view").then(() => {
          this.$refs["form"].initialize();
          this.$refs["incoming-order-items"].getData();
        });
      }
    },
    updateComputedFields() {
      let orderItems = this.$refs["incoming-order-items"].getDataSet();

      let itemsTotal = orderItems.reduce((a, b) => +a + +b["Line Cost"], 0);

      this.data["Total"] = this.$helpers.round(
        itemsTotal - this.data["Discount"],
        2
      );

      this.data.items = orderItems;
    },
    onFileContainerLoad(count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`;
    }
  },
  watch: {}
};
</script>

<style></style>
